import qrLogoPath from '../../assets/qr-logo.png';
import copyIconPath from '../../assets/copy.svg';
import okIconPath from '../../assets/ok.svg';

const $ = (selector) => document.querySelector(selector);

const toggle = (name, isVisible, display) => {
  $(name).style.display = isVisible ? (display || 'flex') : 'none';
};

const setScreen = (name) => {
  toggle('#startScreen', name === 'startScreen');
  if (name === 'startScreen') {
    setTimeout(() => {
      $('.start-input').focus();
    }, 10);
  }
  toggle('#navInput', name !== 'startScreen');
  toggle('.main', name !== 'startScreen');
  toggle('#auctionDomainScreen', name === 'auctionDomainScreen', 'block');
  toggle('#busyDomainScreen', name === 'busyDomainScreen', 'block');
  toggle('#freeDomainScreen', name === 'freeDomainScreen', 'block');
  toggle('#domainStatus', name !== 'main');
  toggle('#domainLoading', name === 'main');
  if (name === 'auctionDomainScreen') {
    $('#domainStatus').classList.remove('busy');
    $('#domainStatus').classList.add('free');
    $('#domainStatus').innerText = locale.auction;
  } else if (name === 'freeDomainScreen') {
    $('#domainStatus').classList.remove('busy');
    $('#domainStatus').classList.add('free');
    $('#domainStatus').innerText = locale.free;
  } else if (name === 'busyDomainScreen') {
    $('#domainStatus').classList.add('busy');
    $('#domainStatus').classList.remove('free');
    $('#domainStatus').innerText = locale.busy;
  }
};
// LANG

const localeDict = {
  'ru': {
    'testnet': 'ВНИМАНИЕ! Это тестовая сеть, не посылайте настоящие монеты! Тестовые домены могут быть сброшены.',
    'navInputPlaceholder': 'Введите домен',
    'startInputPlaceholder': 'Введите домен, чтобы начать',
    'auction1': 'Проходит аукцион на этот домен.',
    'auction2_1': 'Аукцион закончится',
    'auction2_2': 'через',
    'auction3': 'Лучшая ставка на данный момент:',
    'auctionFrom': 'от',
    'auction4_1': 'Чтобы поднять ставку, отправьте не менее',
    'auction4_2': 'на адрес домена:',
    'openWallet': 'Открыть кошелек',
    'auctionInfo1': 'Если ваша ставка будет перебита, ваши монеты автоматически вернутся на ваш кошелек. Вы сможете сделать новую ставку.',
    'auctionInfo2': 'После окончания аукциона пользователь, сделавший лучшую ставку, забирает домен.',
    'owner': 'Владелец',
    'free1': 'Этот домен свободен.',
    'free2': 'Домен можно приобрести через открытый аукцион.',
    'free3_1': 'Чтобы начать аукцион, сделайте первую ставку, отправив не менее',
    'free3_2': 'на адрес:',
    'free4': 'В комментарии к переводу укажите:',
    'auctionDuration': 'Длительность аукциона:',
    'free': 'Свободен',
    'busy': 'Занят',
    'auction': 'Аукцион',
    'errorLength': 'Минимум 1 символол, максимум 24 символа',
    'subdomainsNotAllowed': 'Субдомены не разрешены',
    'invalidChars': ' Допустимы латинские буквы (a-z), цифры (0-9) и дефис (-). Дефис не может находиться в начале и конце.',
    'week': 'неделя',
    'day': 'день',
    'hour': 'час',
    'days': 'дней',
    'hours': 'часов',
    'min': 'мин.',
    'sec': 'сек',
    'cantCopy': 'Буфер омена не доступен',
    'connect': 'Редактировать',
    'dnsRecords': 'DNS-записи:',
    'notOwner': 'Вы не владелец домена',
    'walletAddress': 'Адрес кошелька:',
    'adnlAddress': 'TON-сайт:',
    'invalidAddress': 'Неверный адрес',
    'resolver': 'Субдомены:',
    'set': 'Сохр.',
    'installExtension': 'Пожалуйста, установите TON Chrome расширение, чтобы редактировать домен.',
    'updateExtension': 'Пожалуйста, обновите расширение TON Chrome.',
    'expires': 'Истекает',
    'send-warning': 'Отправляйте монеты с кошелька, который вы контролируете. Не отправляйте напрямую с бирж или ботов - вы потеряете монеты.',
    'begin': 'Открытый аукцион доменных имен VIP TON DNS начинается сегодня.',
    'about_dns': 'Узнать больше о VIP TON DNS',
    'help': 'Помощь',
    'noWallet': 'Нет кошелька?',
    'openAuction': 'Открытый аукцион доменных имен',
    'activeAuction': '⏱️ Активен',
    'completedAuction': '✅ Завершен',
    'currentBid': 'Текущая ставка',
    'finalBid': 'Итоговая цена',
    'recentAuctions': 'Недавние аукционы',
    'notTransferred': 'домен не переведён в NFT',
  },
  'en': {
    'free': 'Available',
    'busy': 'Taken',
    'auction': 'Auction',
    'errorLength': 'Minimum 1 character, maximum 24 characters',
    'subdomainsNotAllowed': 'Subdomains are not allowed',
    'invalidChars': 'Latin letters (a-z), numbers (0-9) and hyphens (-) are allowed. A hyphen cannot be at the beginning or end.',
    'week': 'week',
    'day': 'day',
    'hour': 'hour',
    'days': 'days',
    'hours': 'hours',
    'min': 'min.',
    'sec': 'sec',
    'cantCopy': 'Can’t copy to clipboard',
    'notOwner': 'You are not the owner of this domain',
    'invalidAddress': 'Invalid address',
    'set': 'Set',
    'installExtension': 'Please update your TON Chrome extension',
    'updateExtension': 'Please install the TON Chrome extension to edit the domain',
    'activeAuction': '⏱️ Active',
    'completedAuction': '✅ Finished',
    'currentBid': 'Current bid',
    'finalBid': 'Final price',
    'recentAuctions': 'Recent auctions',
    'notTransferred': 'not minted',
  },
};

const browserLang = navigator.language || navigator.userLanguage;
const lang = (browserLang === 'ru-RU') || (browserLang === 'ru') || (browserLang === 'be-BY') || (browserLang === 'be') || (browserLang === 'kk-KZ') || (browserLang === 'kk') ? 'ru' : 'en';
const locale = localeDict[lang];

if (lang !== 'en') {
  $('.start-input').placeholder = locale.startInputPlaceholder;
  $('#navInput input').placeholder = locale.navInputPlaceholder;
  document.querySelectorAll('[data-locale]').forEach(div => {
    const key = div.attributes['data-locale'].value;
    div.innerText = locale[key];
  });
  $('#aboutDnsLink').href = 'https://telegra.ph/TON-DNS-06-27';
}

const formatDuration = millis => {
  if (millis < 0) millis = 0;
  let seconds = Math.floor((millis / 1000) % 60);
  let minutes = Math.floor((millis / (1000 * 60)) % 60);
  let hours = Math.floor((millis / (1000 * 60 * 60)) % 24);
  let days = Math.floor((millis / (1000 * 60 * 60 * 24)));

  if (days === 7) {
    return '1 ' + locale.week;
  }

  minutes = (minutes < 10) ? '0' + minutes : minutes;
  seconds = (seconds < 10) ? '0' + seconds : seconds;

  let result = '';
  if (days > 0) {
    result += days + ' ' + (days === 1 ? locale.day : locale.days) + ' ';
  }
  if (days > 0 || hours > 0) {
    result += hours + ' ' + (hours === 1 ? locale.hour : locale.hours) + ' ';
  }
  result += minutes + ' ' + locale.min + ' ' + seconds + ' ' + locale.sec;

  return result;
};

// MAINNET / TESTNET

const isTestnet = window.location.href.indexOf('testnet=true') > -1;
const one_month = 2592000; // 1 month in seconds = 60 * 60 * 24 * 30
if (isTestnet) {
  toggle('.testnet-badge', true, 'block');
  document.body.classList.add('testnet-padding');
}

// Custom settings
const toncenterUrl = isTestnet
  ? 'https://testnet.toncenter.com/api/v2/jsonRPC'
  : 'https://toncenter.com/api/v2/jsonRPC';
const toncenterApiKey = isTestnet
  ? '92f61ccbeef71895ed1da45600124dd5952d32d856f8ff14df6ae029823d309d'
  : 'c971750e0577e90399f1513bd58136fdfa19114bad6efad74ca527c86c3ca6d3';
const dnsCollectionAddress = isTestnet
  ? 'EQAK9Gh4uPPbrubJJyiWt-5OkOCpWKuTmClWNVAQKkKAd29f'
  : 'EQBWG4EBbPDv4Xj7xlPwzxd7hSyHMzwwLB5O6rY-0BBeaixS';
const zone = '.vip', minLength = 1, maxLength = 24;
const auction_start_time = isTestnet ? 1670544000 : 1670544000;
const auction_start_duration_days = 2;

const tonRootAddress = new TonWeb.Address(dnsCollectionAddress).toString(true, true, true); // .ton root smart contract in bounceable form
const tonweb = new TonWeb(new TonWeb.HttpProvider(toncenterUrl, { apiKey: toncenterApiKey }));
const dnsCollection = new TonWeb.dns.DnsCollection(tonweb.provider, {
  address: tonRootAddress,
});

// UI

let updateIntervalId = 0;
let auctionTimerIntervalId = 0;
let freeQrUrl = null;
let auctionQrUrl = null;
let currentDomain = null;
let currentOwner = null;
let currentDnsItem = null;

const clear = () => {
  clearInterval(updateIntervalId);
  clearInterval(auctionTimerIntervalId);
  freeQrUrl = null;
  auctionQrUrl = null;
  currentDomain = null;
  currentOwner = null;
  currentDnsItem = null;
  $('#busyDomainScreen').classList.remove('edit-expand');
  $('#busyDomainScreen').classList.remove('edit-loading');
};

$('.badge').addEventListener('click', () => {
  clear();
  window.history.pushState('', 'VIP TON DNS ', '#');
  setScreen('startScreen');
});

// SET DOMAIN

const validateDomain = (domain) => {
  if (domain.length < minLength || domain.length > maxLength) {
    return locale.errorLength;
  }
  for (let i = 0; i < domain.length; i++) {
    if (domain.charAt(i) === '.') {
      return locale.subdomainsNotAllowed;
    }
    const char = domain.charCodeAt(i);
    const isHyphen = (char === 45);
    const isValidChar = (isHyphen && (i > 0) && (i < domain.length - 1)) || ((char >= 48) && (char <= 57)) || ((char >= 97) && (char <= 122)); // '-' or 0-9 or a-z ;  abcdefghijklmnopqrstuvwxyz-0123456789

    if (!isValidChar) {
      return locale.invalidChars;
    }
  }
};

const setDomain = (domain) => {
  currentDomain = domain;

  const loadDomain = async () => {
    const domainAddress = await dnsCollection.resolve(domain, TonWeb.dns.DNS_CATEGORY_NEXT_RESOLVER, true);
    const domainAddressString = domainAddress.toString(true, true, true);
    const accountInfo = await tonweb.provider.getAddressInfo(domainAddressString);
    let dnsItem;
    let domainExists = accountInfo.state === 'active';
    let ownerAddress = null;
    if (domainExists) {
      dnsItem = new TonWeb.dns.DnsItem(tonweb.provider, { address: domainAddress });
      const data = await dnsItem.methods.getData();
      if (!data.isInitialized) {
        domainExists = false;
      } else {
        ownerAddress = data.ownerAddress;
      }
    }
    let auctionInfo = null;
    if (domainExists && !ownerAddress) {
      auctionInfo = await dnsItem.methods.getAuctionInfo();
      if (auctionInfo.auctionEndTime < Date.now() / 1000) {
        ownerAddress = auctionInfo.maxBidAddress;
      }
    }

    if (currentDomain === domain) {
      if (!domainExists) {
        setScreen('freeDomainScreen');
        renderFreeDomain(domain);
      } else if (ownerAddress) {
        currentOwner = ownerAddress.toString(true, true, true);
        currentDnsItem = dnsItem;
        setScreen('busyDomainScreen');

        const isTransferred = currentOwner !== 'EQBZ_a1Jzr-HQCXsMN1JTSzdrh0aGHJ1V5BchkSq5a9kYol2';
        $('#busyOwnerAddress').innerText = isTransferred ? shortAddress(currentOwner) : locale.notTransferred;
      } else {
        setScreen('auctionDomainScreen');
        renderAuctionDomain(domain, domainAddressString, auctionInfo);
      }
    }
  };

  clearInterval(auctionTimerIntervalId);
  freeQrUrl = null;
  auctionQrUrl = null;
  currentOwner = null;
  currentDnsItem = null;
  $('#busyDomainScreen').classList.remove('edit-expand');
  $('#busyDomainScreen').classList.remove('edit-loading');
  $('#navInput input').value = '';
  $('.start-input').value = '';

  setScreen('main');
  $('#domainName').innerText = `${resolveEmoji(domain)} ${domain}${zone}`;

  clearInterval(updateIntervalId);
  updateIntervalId = setInterval(() => loadDomain(), 10 * 1000);
  loadDomain();
};

const onInput = (e) => {
  if (e.key === 'Enter') {
    submitDomain(e.target);
  } else {
    toggle('.start-error', false);
  }
};

function submitDomain(input, domain) {
  input ||= $('.start-input');
  domain ||= input.value.toLowerCase().trim();
  if (domain.endsWith(zone)) {
    domain = domain.substring(0, domain.length - 4);
  }
  const error = validateDomain(domain);
  if (error) {
    $('.start-error').innerText = error;
    toggle('.start-error', true);
  } else {
    window.history.pushState(domain, 'VIP TON DNS - ' + domain, '#' + encodeURIComponent(domain));
    setDomain(domain);
  }
}

$('.start-input').addEventListener('keydown', onInput);
$('#navInput input').addEventListener('keydown', onInput);
$('#startBtn').addEventListener('click', () => {
  submitDomain();
});

const processUrl = () => {
  clear();
  const domainFromUrl = decodeURIComponent(location.hash.substring(1)).toLowerCase();
  if (domainFromUrl) {
    const error = validateDomain(domainFromUrl);
    if (error) {
      setScreen('startScreen');
    } else {
      setDomain(domainFromUrl);
    }
  } else {
    setScreen('startScreen');
  }
};
processUrl();

window.onpopstate = () => processUrl();

// RENDER UI

const renderQr = (name, url) => {
  const qrCode = new QRCodeStyling({
    'width': 220,
    'height': 220,
    'data': url,
    'margin': 0,
    'qrOptions': { 'typeNumber': '0', 'mode': 'Byte', 'errorCorrectionLevel': 'Q' },
    'imageOptions': { 'hideBackgroundDots': true, 'imageSize': 0.2, 'margin': 0 },
    'dotsOptions': { 'type': 'rounded', 'color': '#000000', 'gradient': null },
    'backgroundOptions': { 'color': '#ffffff' },
    'image': qrLogoPath,
    'cornersSquareOptions': { 'type': 'extra-rounded', 'color': '#000000' },
    'cornersDotOptions': { 'type': 'dot', 'color': '#000000' },
  });

  const canvasContainer = $(name);
  canvasContainer.innerHTML = '';
  qrCode.append(canvasContainer);
};

const shortAddress = (address) => {
  return address.substring(0, 8) + '...' + address.substring(address.length - 8);
};

const getMinPriceConfig = (domainCharCount) => {
  switch (domainCharCount) {
    case 1:
      return ['2999', '299'];
    case 2:
      return ['999', '99'];
    case 3:
      return ['249', '25'];
    case 4:
      return ['49', '5'];
    default:
      return ['10', '1'];
  }
};

const getMinPrice = (domain) => {
  const arr = getMinPriceConfig(domain.length);
  let startMinPrice = TonWeb.utils.toNano(arr[0]);
  const endMinPrice = TonWeb.utils.toNano(arr[1]);
  const nowTime = Math.floor(Date.now() / 1000);
  const seconds = nowTime - auction_start_time;
  const months = Math.floor(seconds / one_month);
  if (months > 21) {
    return endMinPrice;
  }
  for (let i = 0; i < months; i++) {
    startMinPrice = startMinPrice.mul(new TonWeb.utils.BN(90)).div(new TonWeb.utils.BN(100));
  }
  return startMinPrice;
};

const getAuctionDuration = () => {
  const auction_start_duration = 60 * 60 * 24 * auction_start_duration_days;
  const auction_end_duration = 60 * 60;

  const nowTime = Math.floor(Date.now() / 1000);
  const seconds = nowTime - auction_start_time;
  let months = Math.floor(seconds / one_month);
  if (months > 12) {
    months = 12;
  }
  return auction_start_duration - (auction_start_duration - auction_end_duration) * months / 12;
};

const renderAuctionDomain = (domain, domainItemAddress, auctionInfo) => {
  const auctionEndTime = auctionInfo.auctionEndTime; // unixtime
  const bestBidAmount = auctionInfo.maxBidAmount;
  const bestBidAddress = auctionInfo.maxBidAddress.toString(true, true, true);

  const endDate = new Date(auctionEndTime * 1000);

  const setLeftTime = () => {
    const now = Date.now();
    let delta = (auctionEndTime * 1000) - now;
    $('#auctionLeftTime').innerText = formatDuration(delta);
  };

  clearInterval(auctionTimerIntervalId);
  auctionTimerIntervalId = setInterval(() => setLeftTime(), 1000);
  setLeftTime();

  $('#auctionEndTime').innerText = endDate.toLocaleDateString() + ' ' + endDate.toLocaleTimeString();
  $('#auctionAmount').innerText = TonWeb.utils.fromNano(bestBidAmount) + ' TON';
  $('#auctionOwnerAddress').innerText = shortAddress(bestBidAddress);
  $('#auctionAddress').innerText = domainItemAddress;
  $('#auctionMinBet').innerText = TonWeb.utils.fromNano(bestBidAmount.mul(new TonWeb.utils.BN(105)).div(new TonWeb.utils.BN(100))) + ' TON';

  const auctionUrl = 'ton://transfer/' + domainItemAddress;
  $('#auctionBtn').href = auctionUrl;
  if (auctionQrUrl !== auctionUrl) {
    auctionQrUrl = auctionUrl;
    renderQr('#auctionQr', auctionUrl);
  }
};

function onDomainClick(e) {
  submitDomain(undefined, e.target.innerText);
}

const renderAuctionStats = async () => {
  const res = await fetch('https://api.tonnames.org/auction-stats.json');
  if (!res.ok) return;

  const data = (await res.json()).slice(0, 500);
  const htmlParts = [];

  for (const item of data) {
    const status = item.isFinished ? locale.completedAuction : locale.activeAuction;
    const bidLabel = item.isFinished ? locale.finalBid : locale.currentBid;
    const base = item.domain.split('.')[0];

    htmlParts.push(`
      <li class="auction-row">
        <div>
          ${resolveEmoji(base)} <a href="/#${base}" onclick="onDomainClick">${item.domain}</a>        
        </div>
        <div>
          <span class="auction-status auction-status-${item.isFinished ? 'finished' : 'active'}">${status}</span>
          · <span class="bid-label">${bidLabel}</span>: <span class="bid-value">${item.currentBid} 💎</span>
        </div>
      </li>
    `);
  }

  $('#auctionStats').innerHTML = `<h3>${locale.recentAuctions}</h3><ul class="custom-scroll">${htmlParts.join('')}</ul>`;
};

export function resolveEmoji(base) {
  switch (base.length) {
    case 1:
      return '🗝';
    case 2:
      return '💍';
    case 3:
      return '⚜️';
    case 4:
      return '🪙';
    default:
      return '🍀';
  }
}

const renderFreeDomain = (domain) => {
  $('#freeBuyAddress').innerText = tonRootAddress;
  $('#freeComment').innerText = domain;

  $('#freeMinBet').innerText = TonWeb.utils.fromNano(getMinPrice(domain)) + ' TON';
  $('#auctionDuration').innerText = formatDuration(getAuctionDuration() * 1000) + '.';

  const buyUrl = 'ton://transfer/' + tonRootAddress + '?text=' + encodeURIComponent(domain);
  $('#freeBtn').href = buyUrl;
  if (freeQrUrl !== buyUrl) {
    freeQrUrl = buyUrl;
    renderQr('#freeQr', buyUrl);
  }
};

// COPY TO CLIPBOARD

const copyToClipboard = async (name, btnName) => {
  try {
    const div = $(name);
    const btn = $(btnName);
    await navigator.clipboard.writeText(div.innerText);
    btn.style.backgroundImage = `url(${okIconPath})`;
    setTimeout(() => {
      btn.style.backgroundImage = `url(${copyIconPath})`;
    }, 300);
  } catch (e) {
    alert(locale.cantCopy);
  }
};

$('#auctionAddressContainer').addEventListener('click', () => {
  copyToClipboard('#auctionAddress', '#auctionAddressContainer .copy-btn-icon');
});

$('#freeAddressContainer').addEventListener('click', () => {
  copyToClipboard('#freeBuyAddress', '#freeAddressContainer .copy-btn-icon');
});

$('#freeCommentContainer').addEventListener('click', () => {
  copyToClipboard('#freeComment', '#freeCommentContainer .copy-btn-icon');
});

// EDIT

const createEditBtn = (containerName) => {
  const container = $(containerName);
  container.innerHTML = '';
  const btn = document.createElement('a');
  btn.innerText = locale.set;
  btn.classList.add('edit-btn');
  container.appendChild(btn);
  return btn;
};

const connectExtension = async (domain, dnsItem) => {
  const provider = window.ton;

  if (!provider) {
    alert(locale.installExtension);
    return;
  }

  if (!window.tonProtocolVersion || window.tonProtocolVersion < 1) {
    alert(locale.updateExtension);
    return;
  }

  const accounts = await provider.send('ton_requestAccounts');
  const account = new TonWeb.Address(accounts[0]).toString(true, true, true);
  if (account !== currentOwner) {
    alert(locale.notOwner);
    return;
  }

  $('#busyDomainScreen').classList.add('edit-loading');

  try {
    const dnsRecordWallet = await dnsItem.resolve('.', TonWeb.dns.DNS_CATEGORY_WALLET);
    const dnsRecordAdnl = await dnsItem.resolve('.', TonWeb.dns.DNS_CATEGORY_SITE);
    const dnsRecordResolver = await dnsItem.resolve('.', TonWeb.dns.DNS_CATEGORY_NEXT_RESOLVER);
    if (domain === currentDomain) {
      $('#editWalletRow input').value = dnsRecordWallet ? dnsRecordWallet.toString(true, true, true) : '';
      $('#editAdnlRow input').value = dnsRecordAdnl ? dnsRecordAdnl.toHex() : '';
      $('#editResolverRow input').value = dnsRecordResolver ? dnsRecordResolver.toString(true, true, true) : '';

      const setTx = async (key, value) => {
        const dataCell = await TonWeb.dns.DnsItem.createChangeContentEntryBody({
          category: key,
          value: value,
        });
        const data = TonWeb.utils.bytesToBase64(await dataCell.toBoc(false));

        provider.send(
          'ton_sendTransaction',
          [{
            to: (await dnsItem.getAddress()).toString(true, true, true),
            value: TonWeb.utils.toNano('0.05').toString(),
            data: data,
            dataType: 'boc',
          }],
        );
      };

      createEditBtn('#editWalletRow .edit-btn').addEventListener('click', () => {
        const value = $('#editWalletRow input').value;
        if (!value || TonWeb.Address.isValid(value)) {
          setTx(TonWeb.dns.DNS_CATEGORY_WALLET, value ? TonWeb.dns.createSmartContractAddressRecord(new TonWeb.Address(value)) : null);
        } else {
          alert(locale.invalidAddress);
        }
      });
      createEditBtn('#editAdnlRow .edit-btn').addEventListener('click', () => {
        const value = $('#editAdnlRow input').value; // hex
        let record = null;
        if (value) {
          try {
            const adnlAddress = new TonWeb.utils.AdnlAddress(value);
            record = TonWeb.dns.createAdnlAddressRecord(adnlAddress);
          } catch (e) {
            console.error(e);
            alert(locale.invalidAddress);
            return;
          }
        }

        setTx(TonWeb.dns.DNS_CATEGORY_SITE, value ? record : null);
      });
      createEditBtn('#editResolverRow .edit-btn').addEventListener('click', () => {
        const value = $('#editResolverRow input').value;
        if (!value || TonWeb.Address.isValid(value)) {
          setTx(TonWeb.dns.DNS_CATEGORY_NEXT_RESOLVER, value ? TonWeb.dns.createNextResolverRecord(new TonWeb.Address(value)) : null);
        } else {
          alert(locale.invalidAddress);
        }
      });
    }
  } catch (e) {
    console.error(e);
    $('#busyDomainScreen').classList.remove('edit-loading');
    return;
  }

  $('#busyDomainScreen').classList.remove('edit-loading');
  $('#busyDomainScreen').classList.add('edit-expand');
};

$('#connectBtn').addEventListener('click', () => connectExtension(currentDomain, currentDnsItem));
$('#getgemsBtn').addEventListener('click', async () => {
  const address = (await currentDnsItem.getAddress()).toString(true, true, true);
  window.open(`https://getgems.io/collection/EQBWG4EBbPDv4Xj7xlPwzxd7hSyHMzwwLB5O6rY-0BBeaixS/${address}`);
});

void renderAuctionStats();
